var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title: "위험등록부 목록",
            tableId: "riskRegister01",
            columns: _vm.gridRisk.columns,
            data: _vm.riskReduce.riskbooks,
            merge: _vm.gridRisk.merge,
            gridHeight: _vm.setheight,
            editable: _vm.editable && !_vm.disabled,
            selection: "multiple",
            rowKey: "ramSignificantRiskRegisterId",
            checkDisableColumn: "riskRegisterFlag",
          },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props, col }) {
                return [
                  col.name === "riskHazardPicture"
                    ? [
                        _c("q-btn", {
                          attrs: {
                            unelevated: "",
                            round: "",
                            dense: "",
                            size: "7px",
                            color: "primary",
                            icon: "wallpaper",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.openHazardPicture(props)
                            },
                          },
                        }),
                      ]
                    : col.name === "picture"
                    ? [
                        _c("q-btn", {
                          attrs: {
                            unelevated: "",
                            round: "",
                            dense: "",
                            size: "7px",
                            color: "primary",
                            icon: "wallpaper",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.openImprPicture(props)
                            },
                          },
                        }),
                      ]
                    : col.name === "customCol"
                    ? [
                        props.row["sopImprovementIds"] &&
                        props.row["ibmStepNames"]
                          ? _vm._l(
                              _vm.$comm.ibmTagItems(props.row, _vm.colorItems),
                              function (item, index) {
                                return _c(
                                  "q-chip",
                                  {
                                    key: index,
                                    staticStyle: {
                                      "margin-bottom": "4px !important",
                                    },
                                    attrs: {
                                      outline: "",
                                      square: "",
                                      color: item.color,
                                      clickable: true,
                                      "text-color": "white",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.linkClick(item, props.row)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(" " + _vm._s(item.title) + " "),
                                    _c("c-assess-tooltip", {
                                      attrs: {
                                        ibmTooltip: item.ibmTooltip,
                                        ibmClassCd: item.ibmClassCd,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }
                            )
                          : void 0,
                        _vm.editable &&
                        Boolean(props.row.ram4mAssessScenarioId) &&
                        props.row.editFlag !== "C" &&
                        !_vm.disabled
                          ? _c(
                              "div",
                              [
                                _c(
                                  "q-btn-group",
                                  {
                                    staticClass: "ColumInnerBtnGroup",
                                    attrs: { outline: "" },
                                  },
                                  [
                                    _c(
                                      "q-btn",
                                      {
                                        staticClass: "ColumInnerBtn",
                                        attrs: {
                                          icon: "add",
                                          color: "red-6",
                                          "text-color": "white",
                                          align: "center",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.innerBtnClicked(
                                              col,
                                              props
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("q-tooltip", [
                                          _vm._v(" 개선요청등록 "),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: { label: "삭제", icon: "remove" },
                        on: { btnClicked: _vm.removeRisk },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }